<template>
  <component :is="contentComponent" />
</template>
<script>

const mobile = () => import('@/pages/landingMobile.vue') //代码切割
const pc = () => import('@/pages/landingPC.vue') //代码切割
import { mapGetters } from 'vuex';

export default {
  name: 'LandingMain',
  computed: {
    ...mapGetters([
      'deviceType',
    ])
  },
  created () {
    console.log(`deviceType - ${this.deviceType}`);
    if (this.deviceType == 'desktop' || this.deviceType == 'pc') {
      this.contentComponent = pc
      return
    }
    this.contentComponent = mobile
  },
};
</script>
